<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left> mdi-arrow-left </v-icon>
      Regresar a reportes
    </v-btn>

    <div class="card-header flex-nowrap border-0 pt-6 pb-0 rounded-lg pb-5">
      <!-- begin::Page header and title -->
      <template>
        <div class="card-title">
          <h3 class="card-label">
            Resumen de estudiantes inscritos.
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar el resumen de todos los estudiantes
              incritos.</span
            >
          </h3>
        </div>
      </template>
      <!-- end::Page header and title -->
      <!-- begin::filters for report generation-->
      <v-toolbar
        color="primary"
        class="elevation-2 rounded-lg rounded-b-0"
        dark
        flat
      >
        <v-container>
          <p class="text-h5 font-weight-light mb-0 white--text">
            Estudiantes agrupados por:
          </p>
        </v-container>
        <template v-slot:extension>
          <v-tabs v-model="tabs">
            <v-tab> Grupos de especialidad </v-tab>
            <v-tab> Grado y Sección académica </v-tab>
            <v-tab> Niveles educativos </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <!-- end::filters for report generation -->

      <!--Aqui van las tablas y demás etc-->
      <v-tabs-items v-model="tabs" class="elevation-2 rounded-lg rounded-t-0">
        <!-- begin::option 1 - Tabs -->
        <v-tab-item>
          <v-col cols="12">
            <v-data-table
              :headers="students.bySpecialityGroups_TableHeaders"
              :items="students.data.option_one"
              :items-per-page="5"
              :loading="students.isLoading"
            >
              <template v-slot:top>
                <div class="ma-4 text-h6 font-weightlight">
                  Listado de alumnos inscritos separados por grupos de
                  especialidad
                </div>
              </template>
              <template v-slot:[`item.speciality`]="{ item }">
                <!-- begin::student code column -->
                <p class="ma-0 pr-4 pa-0 text-body-1 text-capitalize">
                  {{ item.speciality.toLowerCase() }} Grupo
                  {{ item.group }}
                </p>
                <!-- end::student code column -->
              </template>
              <template v-slot:[`item.degree`]="{ item }">
                <!-- begin::student code column -->
                <p class="ma-0 pr-4 pa-0 text-body-1 text-capitalize">
                  <span class="font-weight-bold">{{
                    item.degree.toLowerCase()
                  }}</span>
                </p>
                <!-- end::student code column -->
              </template>
              <template v-slot:[`item.total_masculine`]="{ item }">
                <!-- begin::student code column -->
                <v-chip class="ma-2" color="blue lighten-1" text-color="white">
                  <v-avatar left>
                    <v-icon>mdi-face-man</v-icon>
                  </v-avatar>
                  {{ item.total_masculine }}
                </v-chip>
                <!-- end::student code column -->
              </template>
              <template v-slot:[`item.total_femenine`]="{ item }">
                <!-- begin::student code column -->
                <v-chip class="ma-2" color="pink lighten-2" text-color="white">
                  <v-avatar left>
                    <v-icon>mdi-face-woman</v-icon>
                  </v-avatar>
                  {{ item.total_femenine }}
                </v-chip>
                <!-- end::student code column -->
              </template>
              <template v-slot:[`item.total`]="{ item }">
                <!-- begin::student code column -->
                <v-chip
                  class="ma-2"
                  color="indigo lighten-2"
                  text-color="white"
                >
                  <v-avatar left>
                    <v-icon>mdi-account-circle</v-icon>
                  </v-avatar>
                  {{ item.total }}
                </v-chip>
                <!-- end::student code column -->
              </template>
            </v-data-table>
          </v-col>
        </v-tab-item>
        <!-- end::option 1 - Tabs -->
        <!-- begin::option 2 - Tabs -->
        <v-tab-item>
          <v-col cols="12">
            <v-data-table
              :headers="students.bySectionGroups_TableHeaders"
              :items="students.data.option_two"
              :items-per-page="5"
              :loading="students.isLoading"
            >
              <template v-slot:top>
                <div class="ma-4 text-h6 font-weightlight">
                  Listado de alumnos inscritos separados por grado y sección
                  académica
                </div>
              </template>
              <template v-slot:[`item.degree`]="{ item }">
                <!-- begin::student code column -->
                <p class="ma-0 pr-4 pa-0 text-body-1 text-capitalize">
                  <span class="font-weight-bold"
                    >{{ item.degree }} {{ item.section }}-{{ item.group }}</span
                  >
                </p>
                <!-- end::student code column -->
              </template>
              <template v-slot:[`item.total_masculine`]="{ item }">
                <!-- begin::student code column -->
                <v-chip class="ma-2" color="blue lighten-1" text-color="white">
                  <v-avatar left>
                    <v-icon>mdi-face-man</v-icon>
                  </v-avatar>
                  {{ item.total_masculine }}
                </v-chip>
                <!-- end::student code column -->
              </template>
              <template v-slot:[`item.total_femenine`]="{ item }">
                <!-- begin::student code column -->
                <v-chip class="ma-2" color="pink lighten-2" text-color="white">
                  <v-avatar left>
                    <v-icon>mdi-face-woman</v-icon>
                  </v-avatar>
                  {{ item.total_femenine }}
                </v-chip>
                <!-- end::student code column -->
              </template>
              <template v-slot:[`item.total`]="{ item }">
                <!-- begin::student code column -->
                <v-chip
                  class="ma-2"
                  color="indigo lighten-2"
                  text-color="white"
                >
                  <v-avatar left>
                    <v-icon>mdi-account-circle</v-icon>
                  </v-avatar>
                  {{ item.total }}
                </v-chip>
                <!-- end::student code column -->
              </template>
            </v-data-table>
          </v-col>
        </v-tab-item>
        <!-- end::option 2 - Tabs -->
        <v-tab-item>
          <v-row class="pa-4">
            <v-col cols="12" md="6" xl="4">
              <registeredStudentsByAcademicLevelChart
                :labels="students.chartLabels"
                :data="students.chartData"
              ></registeredStudentsByAcademicLevelChart>
            </v-col>
            <v-col cols="12" md="6" xl="8">
              <v-row>
                <v-col cols="12" xl="6">
                  <div v-if="false">
                    <v-skeleton-loader
                      type="list-item-avatar-three-line"
                    ></v-skeleton-loader>
                  </div>
                  <div v-else>
                    <div
                      class="pa-5 d-flex rounded-lg dashboard-card flex-column flex-sm-row align-center"
                    >
                      <div
                        class="card-icon-container blue lighten-4 d-flex rounded-circle pa-5 mb-3 mb-sm-0"
                      >
                        <v-icon color="blue mx-auto">mdi-face-man</v-icon>
                      </div>
                      <div class="card-info-container ml-5">
                        <p
                          class="ma-0 text-h5 font-weight-bold text-center text-sm-left"
                        >
                          {{
                            students.data.option_three.data[0].total_masculine
                          }}
                          Alumnos
                        </p>
                        <p class="text-overline ma-0 text-center text-sm-left">
                          Total masculino,
                          <span class="font-weight-bold">Bachillerato</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" xl="6">
                  <div v-if="false">
                    <v-skeleton-loader
                      type="list-item-avatar-three-line"
                    ></v-skeleton-loader>
                  </div>
                  <div v-else>
                    <div
                      class="pa-5 d-flex rounded-lg dashboard-card flex-column flex-sm-row align-center"
                    >
                      <div
                        class="card-icon-container pink lighten-4 d-flex rounded-circle pa-5 mb-3 mb-sm-0"
                      >
                        <v-icon color="pink mx-auto">mdi-face-woman</v-icon>
                      </div>
                      <div class="card-info-container ml-5">
                        <p
                          class="ma-0 text-h5 font-weight-bold text-center text-sm-left"
                        >
                          {{
                            students.data.option_three.data[0].total_femenine
                          }}
                          Alumnas
                        </p>
                        <p class="text-overline ma-0 text-center text-sm-left">
                          Total femenino,
                          <span class="font-weight-bold">Bachillerato</span>
                        </p>
                      </div>
                    </div>
                  </div></v-col
                >
              </v-row>
              <v-divider></v-divider>

              <v-row>
                <v-col cols="12" xl="6">
                  <div v-if="false">
                    <v-skeleton-loader
                      type="list-item-avatar-three-line"
                    ></v-skeleton-loader>
                  </div>
                  <div v-else>
                    <div
                      class="pa-5 d-flex rounded-lg dashboard-card flex-column flex-sm-row align-center"
                    >
                      <div
                        class="card-icon-container indigo lighten-4 d-flex rounded-circle pa-5 mb-3 mb-sm-0"
                      >
                        <v-icon color="indigo mx-auto">mdi-face-man</v-icon>
                      </div>
                      <div class="card-info-container ml-5">
                        <p
                          class="ma-0 text-h5 font-weight-bold text-center text-sm-left"
                        >
                          {{
                            students.data.option_three.data[1].total_masculine
                          }}
                          Alumnos
                        </p>
                        <p class="text-overline ma-0 text-center text-sm-left">
                          Total masculino,
                          <span class="font-weight-bold">Tercer Ciclo</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" xl="6">
                  <div v-if="false">
                    <v-skeleton-loader
                      type="list-item-avatar-three-line"
                    ></v-skeleton-loader>
                  </div>
                  <div v-else>
                    <div
                      class="pa-5 d-flex rounded-lg dashboard-card flex-column flex-sm-row align-center"
                    >
                      <div
                        class="card-icon-container purple lighten-4 d-flex rounded-circle pa-5 mb-3 mb-sm-0"
                      >
                        <v-icon color="purple mx-auto">mdi-face-woman</v-icon>
                      </div>
                      <div class="card-info-container ml-5">
                        <p
                          class="ma-0 text-h5 font-weight-bold text-center text-sm-left"
                        >
                          {{
                            students.data.option_three.data[1].total_femenine
                          }}
                          Alumnas
                        </p>
                        <p class="text-overline ma-0 text-center text-sm-left">
                          Total femenino,
                          <span class="font-weight-bold">Tercer Ciclo</span>
                        </p>
                      </div>
                    </div>
                  </div></v-col
                >
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0"><v-divider></v-divider></v-col>
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="4">
                <div v-if="false">
                  <v-skeleton-loader
                    type="list-item-avatar-three-line"
                  ></v-skeleton-loader>
                </div>
                <div v-else>
                  <div
                    class="pa-5 d-flex rounded-lg dashboard-card flex-column flex-sm-row align-center"
                  >
                    <div
                      class="card-icon-container teal lighten-4 d-flex rounded-circle pa-5 mb-3 mb-sm-0"
                    >
                      <v-icon color="teal mx-auto">mdi-account-group</v-icon>
                    </div>
                    <div class="card-info-container ml-5">
                      <p
                        class="ma-0 text-h5 font-weight-bold text-center text-sm-left"
                      >
                        {{ students.data.option_four.total }} Alumnos
                      </p>
                      <p class="text-overline ma-0 text-center text-sm-left">
                        <span class="font-weight-bold">en total</span>
                      </p>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" lg="4" xl="4">
                <div v-if="false">
                  <v-skeleton-loader
                    type="list-item-avatar-three-line"
                  ></v-skeleton-loader>
                </div>
                <div v-else>
                  <div
                    class="pa-5 d-flex rounded-lg dashboard-card flex-column flex-sm-row align-center"
                  >
                    <div
                      class="card-icon-container green lighten-4 d-flex rounded-circle pa-5 mb-3 mb-sm-0"
                    >
                      <v-icon color="green mx-auto">mdi-face-man</v-icon>
                    </div>
                    <div class="card-info-container ml-5">
                      <p
                        class="ma-0 text-h5 font-weight-bold text-center text-sm-left"
                      >
                        {{ students.data.option_four.total_masculine }} Alumnos
                      </p>
                      <p class="text-overline ma-0 text-center text-sm-left">
                        Total
                        <span class="font-weight-bold">Masculino</span>
                      </p>
                    </div>
                  </div>
                </div></v-col
              >
              <v-col cols="12" sm="6" lg="4" xl="4">
                <div v-if="false">
                  <v-skeleton-loader
                    type="list-item-avatar-three-line"
                  ></v-skeleton-loader>
                </div>
                <div v-else>
                  <div
                    class="pa-5 d-flex rounded-lg dashboard-card flex-column flex-sm-row align-center"
                  >
                    <div
                      class="card-icon-container light-green lighten-4 d-flex rounded-circle pa-5 mb-3 mb-sm-0"
                    >
                      <v-icon color="light-green mx-auto"
                        >mdi-face-woman</v-icon
                      >
                    </div>
                    <div class="card-info-container ml-5">
                      <p
                        class="ma-0 text-h5 font-weight-bold text-center text-sm-left"
                      >
                        {{ students.data.option_four.total_femenine }} Alumnas
                      </p>
                      <p class="text-overline ma-0 text-center text-sm-left">
                        Total
                        <span class="font-weight-bold">femenino</span>
                      </p>
                    </div>
                  </div>
                </div></v-col
              >
            </v-row>
          </v-row>
        </v-tab-item>
        <!-- end::option 3 - Tabs -->
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import registeredStudentsByAcademicLevelChart from "@/components/elements/stats/doughnutChart_registeredStudentsByAcademicLevel.vue";
import dashboardRepository from "@/repositories/dashboardRepository";
export default {
  name: "RegisterStudents",
  title: "Reporte de alumnos registrados | GE ITR",
  components: {
    registeredStudentsByAcademicLevelChart,
  },
  data() {
    return {
      tabs: null,
      students: {
        isLoading: false,
        chartData: [],
        chartLabels: [],
        data: {},
        filters: {},
        bySpecialityGroups_TableHeaders: [
          { text: "Grado", value: "degree" },
          { text: "Especialidad", value: "speciality" },
          { text: "Masculino", value: "total_masculine" },
          { text: "Femenino", value: "total_femenine" },
          { text: "Total Alumnos", value: "total" },
        ],
        bySectionGroups_TableHeaders: [
          { text: "Grado - Sección - Grupo", value: "degree" },
          { text: "Masculino", value: "total_masculine" },
          { text: "Femenino", value: "total_femenine" },
          { text: "Total", value: "total" },
        ],
      },
    };
  },
  mounted() {
    this.getRegisteredStudents();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getRegisteredStudents() {
      this.students.isLoading = true;
      dashboardRepository
        .getRegisteredStudents()
        .then(({ data }) => {
          this.students.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.students.chartLabels = this.students.data.option_three.labels;
          this.students.data.option_three.data.forEach((item) => {
            this.students.chartData.push(item.total);
          });
          this.students.isLoading = false;
        });
    },
  },
  computed: {
    reportsBaseURL() {
      let baseURL;
      if (process.env.REPORTS_APP_ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (process.env.REPORTS_APP_ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }
      return baseURL;
    },

    generateTeacherFacultyReport() {
      return `${this.reportsBaseURL}subject-teachers/list`;
    },
  },
};
</script>

<style>
.card-icon-container {
  aspect-ratio: 1/1;
  height: 100%;
  max-height: 75px;
  max-width: 75px;
}
</style>
