<template>
  <div class="chart-container main-container mx-auto mx-md-0 ">
    <div class=" sizing ">
      <canvas
        id="myChart"
        aria-label="Contador de correcciones y extensiones de perfíl"
        role="img"
      ></canvas>
    </div>
  </div>
</template>

<script>
  import Chart from "chart.js/auto";
  import pattern from "patternomaly";
  export default {
    name: "registeredStudentsByAcademicLevelChart",
    props: {
      labels: { type: Array },
      data: { type: Array },
    },
    mounted() {
      const ctx = document.getElementById("myChart");
      const data = {
        labels: this.labels,
        datasets: [
          {
            //label: "My First Dataset",
            data: this.data,
            backgroundColor: [
              pattern.draw("dot", "#5E35B1"),
              pattern.draw("dash", "#1E88E5"),
            ],
            hoverOffset: 4,
          },
        ],
      };

      const myChart = new Chart(ctx, {
        type: "doughnut",
        data: data,
      });

      myChart;
    },
  };
</script>

<style scoped>
  .sizing {
    position: relative;
    width: 100%;
    padding: 2em;
    border-radius: 8px;
    background-color: #f8f8f8;
  }

  .main-container {
    width: 100%;
  }
</style>
